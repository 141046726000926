export const rowKey = "id";

export const columns = [
  // 门店列表
  {
    title: "订单ID",
    dataIndex: "order_no",
    align: "center",
    width: 160,
    slots: { customRender: "order_no" },
  },
  {
    title: "申请时间",
    dataIndex: "apply_time",
    align: "center",
  },
  {
    title: "审核时间",
    dataIndex: "audit_time",
    align: "center",
  },
  {
    title: "退款状态",
    dataIndex: "refund_status",
    align: "center",
    width: "9%",
    slots: {
      customRender: 'refund_status'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatStatus(val)
  },
  {
    title: "退款时间",
    dataIndex: "refund_time",
    align: "center",
  },
  {
    title: "退款人",
    dataIndex: "refunder",
    align: "center",
    width: "15%",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: "13%",
    slots: { customRender: "operation" },
  },
];

// 0-全部，1-未审核，2-审核通过，3-拒绝，5-已退款
export const statusType = [
  { id: 0, name: "全部" },
  { id: 1, name: "未退款" },
  { id: 2, name: "已退款" },
]

export function formatStatus(val) {
  return statusType.find(el=>el.id ===val).name
}
export default {
  rowKey,
  columns,
  statusType,
};
