<template>
  <div>
    <a-modal :visible.sync="show"
             width="1000px"
             title="退款"
             :okText="formData.refund_pay_type==1 ? '已手动打款' : '提交' "
             cancelText="关闭"
             @ok="handlerOK"
             @cancel="$emit('update:show', false)">

      <div class="footer" style="margin-top:0;">
        <a-form-model :model="form"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="refundDetailRef">
          <a-row>
            <a-col :span="24">
              <a-form-model-item prop
                                 :labelCol="{ span: 2 }"
                                 label="退款理由">
                <span>{{infoData.refund_reason}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row style="margin-bottom: 10px;">
            <a-col :span="24">
              <a-form-model-item prop :labelCol="{ span: 2 }" label="上传图片">
                <img v-for="item in imgList"
                     class="img-class"
                     :src="item.image_url"
                     :key="item.image_url"
                     @click="handlerBigImg(item)" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <base-table ref="shopRef"
                  :customHeight="300"
                  :columnsData="invoiceColumns"
                  rowKey="id"
                  :tableData="goodsData">
          </base-table>

          <!-- 金额 -->
          <a-row>
            <a-col :span="12">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="申请金额">
                <span>{{infoData.apply_refund_amount}}元
                  <span v-if="Number(infoData.apply_refund_freight_amount)>0">（含运费{{infoData.apply_refund_freight_amount}}元）</span>
                </span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-divider class="mt-1 mb-1" />
          <!-- 退款金额 -->
          <a-row>
            <a-col :span="12">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="退款金额">
                <span class="font-bold">{{infoData.audit_refund_amount}}元
                  <span v-if="Number(infoData.apply_refund_freight_amount)>0">（含运费{{infoData.apply_refund_freight_amount}}元）</span>
                </span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- 审核 备注 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="审核备注">
                <span>{{infoData.audit_remarks}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-divider class="mt-1 mb-1" />

          <!-- 退款方式 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="退款方式">
                <a-radio-group name="refund_pay_type" v-model="formData.refund_pay_type">
                  <!-- <a-radio value="2" :disabled="wxAutoRefundDisabled">自动退款（微信）</a-radio>
                  <a-radio value="3" :disabled="aliAutoRefundDisabled">自动退款（支付宝）</a-radio> -->
                  <a-radio value="1">手动打款</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- 财务 备注 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="财务备注">
                <a-input v-model="formData.refund_remarks"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- 财务 退款凭证 -->
          <a-row v-if="formData.refund_pay_type==1">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" label="打款凭证"
                                 class="upload-class">
                <uploadImage type="25" :value.sync="upload_file" />
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-form-model>

      </div>
    </a-modal>

    <a-modal class="preview-class"
             title="凭证图片"
             width="50%"
             :visible="previewVisible"
             :footer="null"
             @cancel="previewVisible = false">
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>

import { getRefundDetail, auditRefund } from '@/api/trading-manage/refund.js'
import { formatGoodsType } from '@/utils/type.js'
import uploadImage from "@/components/upload-image"

export default {
  components:{uploadImage},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
    },
  },
  data () {
    return {
      form: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      previewVisible: false,
      previewImage: '',
      invoiceColumns: [
        {
          title: "产品属性",
          dataIndex: "goods_type",
          align: "center",
          width: "80px",
          slots: {
            customRender: 'type'
          },
          slotsType: 'format',
          slotsFunc: (val) => formatGoodsType(val)
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          width: "40%",
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          width: "30%",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
          width: "30%",
        },
        {
          title: "数量",
          dataIndex: "number",
          align: "center",
          width: "80px",
        },
        {
          title: "商品总价",
          dataIndex: "total_price",
          align: "center",
          width: "80px",
        },
        {
          title: "支付金额",
          dataIndex: "total_deal_price",
          align: "center",
          width: "80px",
          // slots: {
          //   customRender: 'total_price'
          // },
          // slotsType: 'format',
          // slotsFunc: (val) => {
          //   return Math.round(val / this.infoData.origin_goods_amount * this.infoData.apply_refund_goods_amount * 100) / 100
          // }
        },
        {
          title: "是否退款",
          dataIndex: "is_refund",
          align: "center",
          width: "80px",
          slots: {
            customRender: "is_refund",
          },
          slotsType: "format",
          slotsFunc: (val) => {
            return val==2?"退款":"——"
          },
        },
      ],
      goodsData: [],
      imgList: [],
      infoData: {},
      upload_file: "",
      wxAutoRefundDisabled: false,
      aliAutoRefundDisabled: false,
      formData: {
        id: this.id,
        payment_voucher_image_path: '',
        refund_remarks: '',
        refund_pay_type: '1',
      }
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      getRefundDetail({ id: this.id }).then(res => {
        if (res.code === 0) {
          this.infoData = res.data

          let audit_refund_amount = Number(res.data.audit_refund_amount)
          let wx_pay_amount       = Number(res.data.wx_pay_amount) || 0
          let alipay_amount       = Number(res.data.alipay_amount) || 0
          this.wxAutoRefundDisabled = wx_pay_amount < audit_refund_amount
          this.aliAutoRefundDisabled = alipay_amount < audit_refund_amount

          res.data.suborder_list.map((el, j) => {
            //  (1-自提，2-配送，3-快递)
            if (el.order_type === 1) {
              this.formData.audit_pick_up_order_refund_amount = el.audit_refund_amount
            } else if (el.order_type === 2) {
              this.formData.audit_delivery_order_refund_amount = el.audit_refund_amount
            } else {
              this.formData.audit_express_order_refund_amount = el.audit_refund_amount
            }
            this.goodsData = this.goodsData.concat(el.goods_list.map((el, i) => {
              el.id = j + i
              return el
            }))
          })
          this.imgList = res.data.attached_image_list
        }
      })
    },

    handlerOK () {
      let params = Object.assign({}, this.formData)
      if(!this.formData.refund_pay_type){
        this.$message.warning('请选择退款方式')
        return
      }
      if(this.formData.refund_pay_type == 1){
        params.payment_voucher_image_path = this.upload_file
      }else{
        params.payment_voucher_image_path = ""
      }
      
      auditRefund(params).then(res => {
        if (res.code === 0) {
          if(this.formData.refund_pay_type == 1){
            this.$message.success('已提交')
          }else{
            this.$message.success('已处理，系统会自动退款')
          }
          this.$parent.initData()
          this.$emit('update:show', false)
        }
      })
    },

    handlerBigImg (e) {
      this.previewImage = e.image_url
      this.previewVisible = true
    },

    handlerCancel () {
      this.isShow = false
    },
  },
}
</script>

<style lang="less" scoped>
.footer {
  margin: 20px 0;
  .img-class {
    width: 100px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>