import ajax from "@/utils/ajax.js";

///////////////////// 财务：订单退款 ////////////////////
/**
 * 获取退款列表
 * @param {*} params
 * @returns
 */
export function getRefundList(params) {
  return ajax.post("/OrderRefund/getList", params);
}


/**
 * 获取退款详情
 * @param {*} params
 * @returns
 */
export function getRefundDetail(params) {
  return ajax.post("/OrderRefund/detail", params);
}

/**
 * 退款通过
 * @param {*} params
 * @returns
 */
export function auditRefund(params) {
  return ajax.post("/OrderRefund/audit", params);
}

///////////////////// 财务：会员卡余额退款 ////////////////////
/**
 * 获取退款列表
 * @param {*} params
 * @returns
 */
export function getWalletRefundList(params) {
  return ajax.post("/FinancialVipCardRefund/getList", params);
}


/**
 * 获取退款详情
 * @param {*} params
 * @returns
 */
export function getWalletRefundDetail(params) {
  return ajax.post("/FinancialVipCardRefund/detail", params);
}

/**
 * 退款通过
 * @param {*} params
 * @returns
 */
export function auditWalletRefund(params) {
  return ajax.post("/FinancialVipCardRefund/audit", params);
}

